const defaultTheme = {
  backgroundColor: '#f9f9f9',
  layout: {
    container: {
      maxWidth: '1330px',
      margin: '0 auto',
    },
  },
  colors: {
    primary: {
      disabled: '#DEDEFF',
      lighter: '#06E3FF',
      light: '#01A7DB',
      main: '#0000BF',
      gradient:
        ' linear-gradient(90deg, #004C80 -59.81%, #007DB2 63.55%, #00A9DE 173.48%)',
      // 06e3ff
    },
    secondary: {
      disabled: '#F9ECE3',
      main: '#E38933',
    },
    gray: {
      200: '#F5F5FF',
      400: '#75767A',
    },
    danger: {
      light: '#F6E7F0',
      main: '#FF0000',
    },
    success: {
      light: '#D9EDF1',
      main: '#00926C',
    },
    green: {
      main: '#96E637',
      dark: '#6EBE0F',
    },
  },
};

export default defaultTheme;
