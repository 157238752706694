/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../Assets/styles/globals';
import defaultTheme from '../../Assets/styles/themes/default';

import Routes from '../../Routes';

function ErrorComponent({ error }) {
  return (
    <p>
      Ocorreu um erro ao tentar fazer login:
      {' '}
      {error}
    </p>
  );
}

function LoadingComponent() {
  return <p>Autenticação em progresso...</p>;
}

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <HashRouter>
        <Routes />
      </HashRouter>
    </ThemeProvider>
  );
}
export default App;
