/* eslint-disable */

import {
  createContext, useEffect, useState, useMemo,
} from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useSearchParams } from 'react-router-dom';

import { useGetVendedorCode, useGetCoordenadorCode } from '../Pages/Granel/useGranel';

import {
  loginRequest,
  getAuthenticatedClient,
} from '../Config/authConfig';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [seller, setSeller] = useState({});
  const [coordinator, setCoordinator] = useState({});
  const [enableFetchCoordinator, setEnableFetchCoordinator] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [searchParams] = useSearchParams();

  const vendedor = useGetVendedorCode(user?.employeeId);
  const coordenador = useGetCoordenadorCode(user?.employeeId, enableFetchCoordinator);

  async function getUserInfo() {
    let response = {};

    try {
      response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getAllAccounts()[0],
      });
    } catch (e) {
      console.log(e);
      response = await instance.acquireTokenPopup(loginRequest);
    }

    const userInfo = await getAuthenticatedClient(
      account.localAccountId,
      response.accessToken,
    );

    const userAux = { ...userInfo, employeeId: userInfo?.employeeId || searchParams.get('sellerCode') || '' };
    const employeeId = userAux?.employeeId ? parseInt(userAux?.employeeId, 10).toString() : '';

    setUser({ ...userAux, employeeId });
    sessionStorage.setItem('user', JSON.stringify({ ...userAux, employeeId }));
  }

  useEffect(() => {
    getUserInfo();
    sessionStorage.setItem('account', JSON.stringify(account));
  }, []);

  useEffect(() => {
    if (vendedor?.data?.code !== undefined) {
     setSeller(vendedor.data);
     sessionStorage.setItem('seller', JSON.stringify(vendedor.data));
    } else if (vendedor?.isSuccess) {
      setEnableFetchCoordinator(true);
    }
  }, [vendedor.data]);

  useEffect(() => {
    if (coordenador?.data?.code !== undefined) {
      setCoordinator(coordenador.data);
     sessionStorage.setItem('coordinator', JSON.stringify(coordenador.data));
    }
  }, [coordenador.data]);

  return <AuthContext.Provider value={{ user, account, seller, coordinator }}>{children}</AuthContext.Provider>;
}
