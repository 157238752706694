import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Verdana', sans-serif;

  }
  body {
    color: #75767A;
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }

  h1, h2, h3, h4, h5, h6 {
    /* font-weight: 700 !important; */
    font-family: 'Inter', sans-serif;
  }

  * {
  scrollbar-width: thin;
  scrollbar-color: blue rgba(75,85,99,.2);
}

p, li {
  font-size: 0.9375rem;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(75,85,99,.2);
}

*::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-radius: 20px;
  border: 3px solid rgba(75,85,99,.2);
}
`;
