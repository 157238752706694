/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint max-len: ["error", { "code": 1000 }] */
/* eslint-disable no-await-in-loop */
import axios from 'axios';
// import clients from '../__mocks__/clients';
import { apiGranel as api } from './utils/api';

import formatGranelSimulation from './utils/formatGranelSimulation';
import formatSAPRules from './utils/formatSAPRules';

export const getParameterByCod = async (cod) => {
  const res = await api.get(`/api/Parameter/${cod}`);
  return res.data;
};

export const getClients = async (code, withDelegation = true, globalAccess = false, search = '') => {
  if (!code && !globalAccess) { return []; }

  const sellerCode = (!globalAccess ? code : null);

  const res = await api.get('/api/Customer', {
    params: {
      take: 0, sellerCode, withDelegation, search,
    },
  }).then((response) => response.data);

  return res;
};

export const getClientByCod = async (codCliente) => {
  let res = [];
  if (codCliente !== null) {
    res = await api.get(`/api/Customer/${codCliente}/CustomerCode`);
  }

  return res;
};

export const postClient = async (clienteFields) => {
  console.log(clienteFields);
  return clienteFields;
};

export const getFabricaById = async (id) => {
  let res = {};
  if (id !== null) {
    res = await api.get(`/api/Factory/${id}`, { params: { withAddress: true } });
  }

  return res;
};

export const getFabricasVC = async () => {
  const res = await api.get('/api/Factory', { params: { onlyHasMaterial: true, companyType: 1, take: 0 } });

  return res;
};

export const getSegments = async () => {
  const res = await api.get('/api/Segment', { params: { take: 0 } }).then((response) => response.data);

  return res;
};

export const getReasons = async () => {
  const res = await api.get('/api/Reason').then((response) => response.data);

  return res;
};

export const postFabricaVC = async (fabricaVCFields) => {
  console.log(fabricaVCFields);
  return fabricaVCFields;
};

export const getCompaniasConcorrente = async () => {
  const res = await api.get('/api/Company', {
    params: { take: 0, field: 'type', value: 'Concorrente' },
  });

  return res;
};

export const getFabricasConcorrente = async (companyId) => {
  let res = [];
  if (companyId !== null && companyId !== '') {
    res = await api.get(`/api/Factory/${companyId}/Company`, { params: { onlyHasMaterial: true } });
  }

  return res;
};

export const getProdutosFabrica = async (fabricaId) => {
  let res = [];
  if (fabricaId !== null && fabricaId !== '') {
    res = await api.get(`/api/FactoryMaterial/${fabricaId}/Factory`);
  }

  return res;
};

export const postFabricaConcorrente = async (fabricaConcorrenteFields) => {
  console.log(fabricaConcorrenteFields);
  return fabricaConcorrenteFields;
};

export const getCiconsRange = async (factoryMaterialId, type, range) => {
  let res = [];
  if (factoryMaterialId !== null && factoryMaterialId !== '' && range !== null) {
    res = await api.get(`/api/Cicon/DateRange/${factoryMaterialId}`, {
      params: { from: range.from, to: range.to, type },
    });
  }

  return res;
};

export const getCiconsLatest = async (factoryMaterialId, type) => {
  let res = [];
  if (factoryMaterialId !== null && factoryMaterialId !== '') {
    res = await api.get(`/api/Cicon/Latest/${factoryMaterialId}`, {
      params: { quantity: 6, type: type || 2 },
    });
  }

  return res?.data?.reverse();
};

export const getCiconsAverage = async (factoryMaterialId, cicons, type) => {
  let res = [];
  if (factoryMaterialId !== null && factoryMaterialId !== '' && cicons !== null) {
    const ciconType = type !== '' ? type : null;
    res = await api.post(`/api/Cicon/Average/${factoryMaterialId}`, cicons, { params: { ciconType } });
  }

  return res;
};

export const getCiconsStandardDeviation = async (factoryMaterialId, cicons, type) => {
  let res = [];
  if (factoryMaterialId !== null && factoryMaterialId !== '' && cicons !== null) {
    const ciconType = type !== '' ? type : null;
    res = await api.post(`/api/Cicon/StandardDeviation/${factoryMaterialId}`, cicons, { params: { ciconType } });
  }

  return res;
};

export const getCiconsPercentage = async (fields) => {
  let res = [];
  if (fields !== null) {
    res = await api.post('/api/Cicon/GetPercentage/', fields);
  }

  return res;
};

export const getSpecialDiscount = async (factoryMaterialVcId, factoryMaterialCompetitorId, microregionId) => {
  const res = await api.get(`/api/SpecialDiscount/FactoryMaterial/${factoryMaterialVcId}/${factoryMaterialCompetitorId}`, { params: { microregionId } });

  return res;
};

export const getValidateDates = async () => {
  const res = await api.get('/api/ValidateDate/', { params: { take: 0 } });

  return res;
};

export const getDeadlines = async () => {
  const res = await api.get('/api/Deadline/', { params: { take: 0 } });

  return res;
};

export const calculate = async (fields) => {
  const formatedFields = formatGranelSimulation(fields);

  const menorvc = Math.max.apply(null, fields.cicons.ciconsVC.map((cicon) => cicon.value).filter((cicon) => cicon > 0));
  const maiorconc = Math.min.apply(null, fields.cicons.ciconsCompetitor.map((cicon) => cicon.value).filter((cicon) => cicon > 0));

  const formatedCicon = {
    CiconPercentage: (fields.cicons.ciconPercentage || 0),
    AverageVC: fields.cicons.averageVC,
    StandardDeviationVc: fields.cicons.standardDeviationVC,
    AverageComp: fields.cicons.averageCompetitor,
    StandardDeviationComp: fields.cicons.standardDeviationCompetitor,
    BiggestCompetitor: maiorconc,
    LowestVC: menorvc,
  };

  const res = await api.post('/api/ResultSimulation/CalculateResults/', formatedFields, { params: formatedCicon });

  return res;
};

export const postProposta = async (fields) => {
  const formatedFields = formatGranelSimulation(fields);

  let res = {};
  if (formatedFields.Id) {
    res = await api.put('/api/Simulation/', formatedFields);
  } else {
    res = await api.post('/api/Simulation/', formatedFields);
  }

  return res;
};

export const postResultado = async (fields) => {
  const formatedFields = {
    SimulationId: fields.simulationId,
    Regra: fields.regra,
    SugCicon: fields.sugCicon,
    SugestionHomogation: fields.sugestionHomogation,
    MinimalPrice: fields.minimalPrice,
    MinPrice: fields.minimalPrice,
    SugNegPercent: fields.sugNegPercent,
    DescRegraPercent: fields.descRegraPercent,
    ApprovalHierarchy: fields.approvalHierarchy,
  };

  const res = await api.post('/api/ResultSimulation/', formatedFields);

  return res;
};

export const sendProposta = async ({ user, granelFields: fields }) => {
  const { data: customer } = await api.get(`/api/Customer/${fields.cliente.customerId}`);
  const { data: factoryMaterial } = await api.get(`/api/FactoryMaterial/${fields.fabricaVC.factoryMaterialId}`);
  const { data: segment } = await api.get(`/api/Segment/${fields.cliente.segment}`);
  const reasons = await getReasons();
  const reason = reasons.find((r) => r.id === fields?.cliente?.reason);
  const reasonCode = reason ? parseInt(reason.code, 10) : null;
  fields.cliente.reasonCode = reasonCode;

  const formatedFields = formatSAPRules(fields, user, customer, factoryMaterial, segment);

  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.I_CONDICAO3) {
    throw new Error('Condição não selecionada!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.I_COND_TYPE) {
    throw new Error('Condição não selecionada!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.DATAB) {
    throw new Error('Data de início da vigência não foi informada!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.DATBI) {
    throw new Error('Data final da vigência não foi informada!');
  }
  if (['4CPE_Deadline', '4CPE_Price_Deadline'].includes(fields.condition) && !fields.deadline) {
    throw new Error('Prazo não foi selecioado!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.ZZMOTIVO) {
    throw new Error('Motivo não selecionado na proposta!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.I_PERNR) {
    throw new Error('Não foi encontrado o número do funcionário, verifique os dados da sua conta na azure ou entre em contato com o suporte!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.GRP_SEGTO) {
    throw new Error('Não foi encontrado o grupo do segmento!');
  }
  if (!formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.CHAVE) {
    throw new Error('Não foi possível montar a chave para o envio para o portal de aprovação!');
  }

  const queryParams = new URLSearchParams({
    senderParty: 'PY_VC_MOBILIDADE',
    senderService: 'BC_VC_MOBILIDADE',
    receiverParty: '',
    receiverService: 'E04_CLNT_150',
    interface: 'SI_MOBILIDADE_REQUEST_MaintainPriceList_out_sync',
    interfaceNamespace: 'http://vc.mobilidade.MaintainPriceList',
  }).toString();

  const resProposal = await api.post('/api/ProposalSAP/', {
    SimulationId: fields.resultados.simulationId,
    Protocol: `${formatedFields.ZFSD_MAINTAIN_PRICE_LIST.I_NUM_EXTERNO}`,
    Key: `${formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.CHAVE}`,
    Condition: `${fields.condition}`,
    ValidateDays: `${fields.validateDate}`,
    EmployeeId: `${user?.employeeId}`,
  });

  if (resProposal.isSuccess && resProposal.data?.id) {
    const externalNumber = `GR${(`00000000${resProposal.data.id}`).slice(-8)}`;

    formatedFields.ZFSD_MAINTAIN_PRICE_LIST.I_NUM_EXTERNO = externalNumber;
    formatedFields.ZFSD_MAINTAIN_PRICE_LIST.T_LP800.item.NUM_EXTERNO = externalNumber;

    const url = process.env.REACT_APP_VOTORANTIM_FIORI ? `${process.env.REACT_APP_VOTORANTIM_FIORI}/maintainpricelist/MessageServlet` : 'https://api.votorantim.com.br/vc/v1/sap/maintainpricelist/MessageServlet';
    const keyId = process.env.REACT_APP_VOTORANTIM_FIORI_KEY_ID || 'effed6fe-75c0-4b87-8319-5de4240e2145';

    // const url = process.env.REACT_APP_VOTORANTIM_FIORI ? `${process.env.REACT_APP_VOTORANTIM_FIORI}/maintainpricelist/MessageServlet` : 'https://apiqa.votorantim.com.br:8443/vc/v1/sap/maintainpricelist/MessageServlet';
    // const keyId = process.env.REACT_APP_VOTORANTIM_FIORI_KEY_ID || 'b321208a-de8c-4b6f-8cc3-b66a63c21e19';

    const res = await axios.post(`${url}?${queryParams}`, formatedFields, {
      headers: {
        KeyId: keyId,
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  return formatedFields.ZFSD_MAINTAIN_PRICE_LIST.I_NUM_EXTERNO;
};

export const sendEmail = async (fields) => {
  const formatedFields = {
    ToEmail: fields.emailTo || '',
    Subject: fields.subject,
    Body: `${fields.body}`,
  };

  let res = { data: null, isSuccess: false };
  if (fields.emails && fields.emails !== undefined && Array.isArray(fields.emails)) {
    for (let i = 0; i < fields.emails.length; i += 1) {
      formatedFields.ToEmail = fields.emails[i];

      res = await api.post('/api/email/send', formatedFields, { headers: { 'Content-Type': 'multipart/form-data' } });
    }
  } else {
    res = await api.post('/api/email/send', formatedFields, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  const resProposal = await api.post(`/api/Simulation/SendEmail/${fields.id}`);

  return res;
};

export const getSimulations = async (accountId, sellerCode = null, s = null) => {
  const params = {
    take: 0, accountId, sellerCode, cleanLoad: true,
  };
  if (s && s !== '' && s !== null) {
    params.field = s?.field;
    params.value = s?.value;
  }

  const res = await api.get('/api/Simulation/', { params });

  return res;
};

export const getSimulation = async (simulationId) => {
  let res = {};
  if (simulationId && simulationId !== null) {
    res = await api.get(`/api/Simulation/${simulationId}`);
  }

  return res;
};

// Employee
export const getEmployee = async (formData) => {
  const res = await api.post('/api/Employee/', formData);

  return res;
};

export const getVendedores = async (email) => {
  const res = await api.get('/api/Seller', {
    params: {
      take: 0,
      // office: 1,
      // email,
      // withDelegation: false,
    },
  }).then((response) => response.data);

  return res;
};

export const getDelegations = async (sellerOriginCode, sellerDelegationCode) => {
  if (!sellerOriginCode || !sellerDelegationCode) {
    return [];
  }

  const res = await api.get(`/api/Delegation/${sellerOriginCode}/${sellerDelegationCode}`).then((response) => response.data);
  return res;
};

export const sendDelegation = async (delegationFields) => {
  const res = await api.post('/api/Delegation', delegationFields).then((response) => response.data);
  return res;
};

export const sendDelegationMassive = async (delegationFields) => {
  const res = await api.post('/api/Delegation/Massive', delegationFields).then((response) => response.data);
  return res;
};

export const deleteDelegation = async (id) => {
  const res = await api.delete(`/api/Delegation/${id}`).then((response) => response);
  return res;
};

export const deleteDelegationMassive = async (ids) => {
  const res = await api.post('/api/Delegation/Massive/Delete', ids).then((response) => response);
  return res;
};

export const getVendedorEmail = async (email) => {
  const res = await api.get('/api/Seller/Email', { params: { email } }).then((response) => response.data);
  return res;
};

export const getVendedorByCode = async (code) => {
  if (!code) {
    return false;
  }
  const res = await api.get(`/api/Seller/${code}/SellerCode`).then((response) => response.data);
  return res;
};

export const getCoordenadorByCode = async (code) => {
  if (!code) {
    return false;
  }
  const res = await api.get(`/api/Coordinator/${code}/CoordinatorCode`).then((response) => response.data);
  return res;
};

export const getStatusFiori = async (NUM_EXTERNO) => {
  const url = process.env.REACT_APP_VOTORANTIM_FIORI ? `${process.env.REACT_APP_VOTORANTIM_FIORI}/returnpricelist/MessageServlet`
    : 'https://apiqa.votorantim.com.br:8443/vc/v1/sap/returnpricelist/MessageServlet';

  const keyId = process.env.REACT_APP_VOTORANTIM_FIORI_KEY_ID || '82620b1b-c8dd-4785-9209-f1734b0d4d84';

  const queryParams = new URLSearchParams({
    senderParty: 'PY_VC_MOBILIDADE',
    senderService: 'BC_VC_MOBILIDADE',
    receiverParty: '',
    receiverService: 'E04_CLNT_150',
    interface: 'SI_MOBILIDADE_REQUEST_ReturnPriceList_out_sync',
    interfaceNamespace: 'http://vc.mobilidade.ReturnPriceList',
  }).toString();

  const body = {
    ZFSD_RETURN_PRICE_LIST: {
      I_BUKRS: 4014,
      I_UNIDADE: 'VCBR',
      T_GROUP: '',
      T_NUM_EXTERNO: {
        item: {
          TIPODOC: 'LP',
          NUM_EXTERNO,
        },
      },
      T_RETURN: '',
      T_STATUS: '',
    },
  };

  const res = await axios
    .post(`${url}?${queryParams}`, body, {
      headers: {
        KeyId: keyId,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((response) => JSON.parse(response.data.replace(/.$/, '')));

  return res;
};
