/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
export function mesAnterior(date, diff) {
  const d = new Date(date);
  d.setMonth(d.getMonth() + diff);
  return d;
}

export const getValidCicons = (cicons, quantity = 3) => {
  let result = cicons;

  if (cicons.length > quantity) {
    result = cicons.filter((cicon) => cicon.value > 0).slice(-quantity);
  }

  return result;
};

export default function dataNosSeisUltimosMeses(data) {
  const datas = [];
  for (let i = 1; i < 7; i++) {
    datas.push(mesAnterior(data, i * -1));
  }
  return datas;
}
