/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.primary.main};
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    background: #F5F5FF;
    border-radius: 0.5rem;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    max-width: 100%;

    img {
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
    }
  }

`;
