const formatGranelSimulation = (fields) => {
  const competitorPrice = fields.concorrente.competitorPrice ? fields.concorrente.competitorPrice.replace(' ', '').replace(',', '.') : 0;
  const VCPrice = fields.fabricaVC.VCPrice.replace(' ', '').replace(',', '.');
  const expenditure = fields.cliente.expenditure.replace(' ', '').replace(',', '.');
  const homologationVC = fields?.fabricaVC?.homologationVC ? fields?.fabricaVC?.homologationVC.replace(' ', '').replace(',', '.') : 0.0;
  const homologationCompetitor = fields?.concorrente?.homologationCompetitor ? fields?.concorrente?.homologationCompetitor.replace(' ', '').replace(',', '.') : 0.0;

  const formatedFields = {
    Id: fields.resultados.simulationId,
    CustomerId: fields.cliente.customerId || null,
    CustomerName: fields.cliente.name || fields.cliente.corporateName,
    CodeSegment: fields.cliente.segment.toString(),
    UF: fields.cliente.uf?.value?.toString() || fields.cliente.uf?.toString(),
    City: fields.cliente.cidade?.value?.toString() || fields.cliente.cidade?.toString(),
    CompetitorFactoryMaterialId:
      fields.concorrente.factoryMaterialId ? fields.concorrente.factoryMaterialId : null,
    CompetitorPrice: parseFloat(competitorPrice),
    VCFactoryMaterialId: fields.fabricaVC.factoryMaterialId,
    VCPrice: parseFloat(VCPrice),
    SegmentId: fields.cliente.segment,
    SpontaneousCustomerInfo: fields.spontaneousCustomerInfo ?? false,
    Incoterms: fields?.cliente?.incoterms ?? 1,
    Expenditure: parseFloat(expenditure),
    Type: fields?.cliente?.type ?? 1,
    Reason: fields?.cliente?.reason ?? 1,
    ReasonId: fields?.cliente?.reason ?? 1,
    TaxBenefit: fields.cliente.taxBenefit || null,
    DistanceFactoryVC: fields.distanceFactoryVC,
    DistanceFactoryCompetitor: fields.distanceFactoryCompetitor,
    HomologationVC: parseFloat(homologationVC) || 0.0,
    HomologationCompetitor: parseFloat(homologationCompetitor) || 0.0,
    HomeAccountId: fields?.homeAccountId,
    specialDiscount: (fields?.cicons?.specialDiscount || 0),
    EmployeeName: (fields?.user?.displayName || null),
    SellerCode: (fields?.user?.employeeId || null),
  };

  // if (formatedFields.taxBenefit) {
  //   delete formatedFields.taxBenefit;
  // }
  return formatedFields;
};

export default formatGranelSimulation;
