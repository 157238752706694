const incotermsOptions = {
  1: 'CIF',
  2: 'FOB',
};

const formatSAPRules = (fields, user, customer, factoryMaterial, segment) => {
  const org = `${(factoryMaterial?.organization || '4014').toString().padStart(4, '0')}`;

  let key = '';
  key += org;
  key += `/${(factoryMaterial?.channel || '').toString().padStart(2, '0')}`;
  key += `/${(factoryMaterial?.sector || '').toString().padStart(2, '0')}`;

  if (['4CPE_Price', '4CPE_Deadline', '4CPE_Price_Deadline'].includes(fields.condition)) {
    key += `/${(customer?.groupPrice || '').toString().padStart(2, '0')}`;
  }

  key += `/${(customer?.customerCode || '').toString().padStart(10, '0')}`;

  if (['4CPE_Price', '4CPE_Deadline', '4CPE_Price_Deadline'].includes(fields.condition)) {
    key += `/${(factoryMaterial?.factory?.factoryCode || '').toString().padStart(4, '0')}`;
    key += `/${incotermsOptions[fields.cliente.incoterms]}`;
  }

  key += `/${(factoryMaterial?.material?.materialCode || '').toString().padStart(18, '0')}`;

  if (fields.condition === '4AFX') {
    key += `/${fields.resultados.descRegraPercent}`;
  }

  const shortCondition = fields.condition.split('_')[0];
  const shortText = fields.shortText || shortCondition;
  const externalNumber = `G${(`000000000${fields.resultados.simulationId}`).slice(-9)}`;

  const DATAB = `${fields.inicioVigencia ? fields.inicioVigencia.split('/').reverse().join('-') : ''}`;
  const DATBI = `${fields.fimVigencia ? fields.fimVigencia.split('/').reverse().join('-') : ''}`;

  let KBETR = '';
  if (fields.condition !== '4CPE_Deadline') {
    const VCPrice = fields.fabricaVC.VCPrice.replace(',', '.').replaceAll(' ', '');
    KBETR = (fields.condition === '4AFX' ? fields.resultados.descRegraPercent : parseFloat(VCPrice || 0));
  }

  let ZTERM = '';
  if (['4CPE_Deadline', '4CPE_Price_Deadline'].includes(fields.condition)) {
    ZTERM = fields.deadline;
  }

  const formatedFields = {
    ZFSD_MAINTAIN_PRICE_LIST: {
      I_BUKRS: 4014,
      I_CONDICAO3: fields.condition === '4AFX' ? '4AFXA' : '4CPEA',
      I_COND_TYPE: shortCondition,
      I_MAT_NOVO: '',
      I_MOTIVO: '',
      I_NUM_EXTERNO: externalNumber,
      I_TEXTO_CURTO: shortText,
      I_TEXTO_LONGO: `Enviado via Simulador \n ${fields.justificativa} \n ${fields.justificativaFixa}`,
      I_PERNR: (customer.codeSeller || user?.employeeId)
        ? (customer.codeSeller || user?.employeeId) : false,
      I_TIPO: 'I',
      I_TP_LISTA: 'V',
      I_UNIDADE: 'VCBR',
      T_LP800: {
        item: {
          DMKEY: '',
          NUM_EXTERNO: externalNumber,
          NUMC: 1,
          CHAVE: key,
          KBETR,
          KBETR_OLD: 0,
          KONWA: 'BRL',
          KPEIN: 1,
          KMEIN: '',
          KRECH: '',
          KZBZG: '',
          STFKZ: '',
          ZTERM,
          DATAB,
          DATBI,
          DIFERENCA: 0,
          Z_OBS: shortText,
          IMP_MERC: 0,
          EMAIL_CLI_1: '',
          // ZVALUE: 0,
          // PRECO_RELATIVO: 0,
          GRP_MATERIAIS: factoryMaterial?.material?.materialCodeGroup || '4E',
          UF_REGIAO: (
            customer?.address?.city?.microrregiao?.mesorregiao?.uf?.sigla || fields.cliente?.uf
          ),
          GRP_SEGTO: segment?.code,
          ZZFIMVIG: fields.condition !== '4AFX' ? DATBI : '',
          ZZMOTIVO: fields?.cliente?.reasonCode ? fields?.cliente?.reasonCode.toString().padStart(2, '0') : '',
        },
      },
      T_RETURN: {
        item: {
          TIPODOC: '',
          DMKEY: '',
          NUM_EXTERNO: '',
          RELCODE: '',
          USERLIB: '',
          NOME_APROV: '',
          SERLIB_SUBST: '',
          NOME_SUBST: '',
          MSG_DATE: '',
          MSG_TIME: '',
          RELEASE_DATE: '',
          RELEASE_TIME: '',
          TYPE: '',
          OBS: '',
        },
      },
    },
  };

  return formatedFields;
};

export default formatSAPRules;
