import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from './styles';

function SuspenseLoader({ blocked }) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box blocked={blocked}>
      <div className="lds-dual-ring" />
    </Box>
  );
}

export default SuspenseLoader;
