/* eslint-disable no-param-reassign */
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { clientGranel, clientImo, clientCpp } from '../../Services/utils/request';
import { loginRequest } from '../../Config/authConfig';

function Request(props) {
  const { instance } = useMsal();
  instance.setActiveAccount(instance.getAllAccounts()[0]);

  clientGranel.interceptors.request.use(async (config) => {
    if (!instance.getAllAccounts() || !instance.getAllAccounts()[0]) {
      throw Error('Sem conta ativa! Verifique se um usuário fez login.');
    }

    let response = { accessToken: null };
    try {
      response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: (instance.getAllAccounts()[0]),
      });
    } catch (e) {
      response = await instance.acquireTokenPopup(loginRequest);
    }

    const bearer = `Bearer ${response.accessToken}`;

    config.headers.Authorization = bearer;
    return config;
  });

  clientImo.interceptors.request.use(async (config) => {
    if (!instance.getAllAccounts() || !instance.getAllAccounts()[0]) {
      throw Error('Sem conta ativa! Verifique se um usuário fez login.');
    }

    let response = { accessToken: null };
    try {
      response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: (instance.getAllAccounts()[0]),
      });
    } catch (e) {
      response = await instance.acquireTokenPopup(loginRequest);
    }

    const bearer = `Bearer ${response.accessToken}`;

    config.headers.Authorization = bearer;
    return config;
  });

  clientCpp.interceptors.request.use(async (config) => {
    if (!instance.getAllAccounts() || !instance.getAllAccounts()[0]) {
      throw Error('Sem conta ativa! Verifique se um usuário fez login.');
    }

    let response = { accessToken: null };
    try {
      response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: (instance.getAllAccounts()[0]),
      });
    } catch (e) {
      response = await instance.acquireTokenPopup(loginRequest);
    }

    const bearer = `Bearer ${response.accessToken}`;

    config.headers.Authorization = bearer;
    return config;
  });

  const { children } = props;

  return (
    <>
      <div />
      {children}
    </>
  );
}

export default Request;
