/* eslint-disable import/prefer-default-export */
import client, {
  clientGranel, clientImo, clientCpp, clientCppWithoutAuth, clientImoWithoutAuth,
} from './request';

const requestCatchError = (err) => ({
  status: err.response?.status,
  msg: err.response?.data?.message || 'Ocorreu um erro na sua solicitação',
  errors: err.response?.data?.errors || [],
});

export const apiGranel = {
  get: (path, params = {}) => clientGranel
    .get(path, params)
    .then((res) => res.data)
    .catch(),
  post: (path, body, params = {}) => clientGranel
    .post(path, body, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  patch: (path, body) => clientGranel
    .patch(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  put: (path, body) => clientGranel
    .put(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  delete: (path) => clientGranel
    .delete(path)
    .then((res) => res.data)
    .catch(requestCatchError),
};

export const apiImo = {
  get: (path, params = {}) => clientImo
    .get(path, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  post: (path, body, params = {}) => clientImo
    .post(path, body, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  patch: (path, body) => clientImo
    .patch(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  put: (path, body) => clientImo
    .put(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  delete: (path) => clientImo
    .delete(path)
    .then((res) => res.data)
    .catch(requestCatchError),
};

export const apiImoWithoutAuth = {
  get: (path, params = {}) => clientImoWithoutAuth
    .get(path, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  post: (path, body, params = {}) => clientImoWithoutAuth
    .post(path, body, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  patch: (path, body) => clientImoWithoutAuth
    .patch(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  put: (path, body) => clientImoWithoutAuth
    .put(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  delete: (path) => clientImoWithoutAuth
    .delete(path)
    .then((res) => res.data)
    .catch(requestCatchError),
};

export const apiCpp = {
  get: (path, params = {}) => clientCpp
    .get(path, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  post: (path, body, params = {}) => clientCpp
    .post(path, body, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  patch: (path, body) => clientCpp
    .patch(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  put: (path, body) => clientCpp
    .put(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  delete: (path) => clientCpp
    .delete(path)
    .then((res) => res.data)
    .catch(requestCatchError),
};

export const apiCppWithoutAuth = {
  get: (path, params = {}) => clientCppWithoutAuth
    .get(path, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  post: (path, body, params = {}) => clientCppWithoutAuth
    .post(path, body, params)
    .then((res) => res.data)
    .catch(requestCatchError),
  patch: (path, body) => clientCppWithoutAuth
    .patch(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  put: (path, body) => clientCppWithoutAuth
    .put(path, body)
    .then((res) => res.data)
    .catch(requestCatchError),
  delete: (path) => clientCppWithoutAuth
    .delete(path)
    .then((res) => res.data)
    .catch(requestCatchError),
};

export const api = {
  get: (path, params = {}) => client
    .get(path, params)
    .then((res) => res.data)
    .catch((err) => ({
      status: err.response.status,
      msg: 'Ocorreu um erro na sua solicitação',
      retorno: err.response.data,
    })),
  post: (path, body, params = {}) => client
    .post(path, body, params)
    .then((res) => res.data)
    .catch((err) => ({
      status: err.response.status,
      msg: 'Ocorreu um erro na sua solicitação',
      retorno: err.response.data,
    })),
  patch: (path, body) => client
    .patch(path, body)
    .then((res) => res.data)
    .catch((err) => ({
      status: err.response.status,
      msg: 'Ocorreu um erro na sua solicitação',
      retorno: err.response.data,
    })),
  put: (path, body) => client
    .put(path, body)
    .then((res) => res.data)
    .catch((err) => ({
      status: err.response.status,
      msg: 'Ocorreu um erro na sua solicitação',
      retorno: err.response.data,
    })),
  delete: (path) => client
    .delete(path)
    .then((res) => res.data)
    .catch((err) => ({
      status: err.response.status,
      msg: 'Ocorreu um erro na sua solicitação',
      retorno: err.response.data,
    })),
};
