/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
import { lazy, Suspense } from 'react';
import { Route, Routes as Router } from 'react-router-dom';

import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from '@azure/msal-react';
import SuspenseLoader from '../Components/SuspenseLoader';
import { AuthProvider } from '../Contexts/auth';

import Auth from '../Pages/Auth/Login';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Home = Loader(lazy(() => import('../Pages/Home')));
const Granel = Loader(lazy(() => import('../Pages/Granel')));
const CPP = Loader(lazy(() => import('../Pages/CPP')));
const PropostaComercial = Loader(lazy(() => import('../Pages/CPP/PropostaComercial')));
const PropostaComercialCockPit = Loader(lazy(() => import('../Pages/CPP/PropostaComercial/Cockpit')));
const CPPContratoSubDigitalAprovar = Loader(lazy(() => import('../Pages/CPP/Components/Contratos/ContratoCliente')));
const CPPContratoSubDigital = Loader(lazy(() => import('../Pages/CPP/Components/Contratos/SubDigital')));
const CPPContratoSubFisico = Loader(lazy(() => import('../Pages/CPP/Components/Contratos/SubFisico')));
const GuiaVendedor = Loader(lazy(() => import('../Pages/GuiaVendedor/Main')));
const ContatoUsinas = Loader(lazy(() => import('../Pages/GuiaVendedor/ContatoUsinas')));
const VendedoresUsinas = Loader(lazy(() => import('../Pages/GuiaVendedor/VendedoresUsinas')));
const CidadesAtendidas = Loader(lazy(() => import('../Pages/GuiaVendedor/CidadesAtendidas')));
const GuiaTraco = Loader(lazy(() => import('../Pages/GuiaVendedor/GuiaTraco')));
const IMO = Loader(lazy(() => import('../Pages/IMO')));
const PropostaComercialImo = Loader(lazy(() => import('../Pages/IMO/PropostaComercial')));
const Spectra = Loader(lazy(() => import('../Pages/IMO/Spectra')));
const GuiaDoVendedor = Loader(lazy(() => import('../Pages/GuiaVendedor/GuiaDoVendedor')));
const GuiContrato = Loader(lazy(() => import('../Pages/GuiaVendedor/GuiContrato')));
const IMOContratoSubDigital = Loader(lazy(() => import('../Pages/IMO/Components/Contratos/SubDigital')));
const IMOContratoSubFisico = Loader(lazy(() => import('../Pages/IMO/Components/Contratos/SubFisico')));
const AprovacoesIMO = Loader(lazy(() => import('../Pages/IMO/Components/Aprovacoes/index')));
const AprovadoresIMO = Loader(lazy(() => import('../Pages/IMO/Components/Aprovacoes/aprovadores')));
const ResumoEmailViewIMO = Loader(lazy(() => import('../Pages/IMO/Components/Emails/ResumoPDFView')));
const AprovacaoEmailViewIMO = Loader(lazy(() => import('../Pages/IMO/Components/Emails/AprovacaoPDFView')));
const GerenciamentoClientesIMO = Loader(lazy(() => import('../Pages/IMO/GerenciamentoClientes/index')));
const GerenciamentoClientesCPP = Loader(lazy(() => import('../Pages/CPP/GerenciamentoClientes/index')));
const GerenciamentoClientesGranel = Loader(lazy(() => import('../Pages/Granel/GerenciamentoClientes/index')));
const GerenciamentoSimulacoesGranel = Loader(lazy(() => import('../Pages/Granel/GerenciamentoSimulacoes/index')));
const VisualizarSimulacoesGranel = Loader(lazy(() => import('../Pages/Granel/GerenciamentoSimulacoes/show')));
const AprovacoesCPP = Loader(lazy(() => import('../Pages/CPP/Components/Aprovacoes/index')));
const AprovadoresCPP = Loader(lazy(() => import('../Pages/CPP/Components/Aprovacoes/aprovadores')));
const ResumoEmailCPP = Loader(lazy(() => import('../Pages/CPP/Components/Emails/ResumoPreview')));
const EmailContato = Loader(lazy(() => import('../Pages/CPP/Components/Emails/ResumoApproval')));
const ContratoClienteIMO = Loader(lazy(() => import('../Pages/IMO/Components/Contratos/ContratoCliente')));
const NovasPropostasAbertasIMO = Loader(lazy(() => import('../Pages/IMO/Components/NovasPropostasAbertas/index')));

function Routes() {
  return (
    <>
      <UnauthenticatedTemplate>
        <Router>
          <Route exact path="/cliente/contato" element={<EmailContato message="Teste" />} />
          <Route exact path="/cliente/sub-digital/:proposal" element={<CPPContratoSubDigitalAprovar />} />
          <Route exact path="/cliente/imo/sub-digital/:proposal" element={<ContratoClienteIMO />} />
          <Route exact path="/cliente/sub-fisico/:proposal" element={<CPPContratoSubFisico />} />
          <Route path="*" element={<Auth />} />
        </Router>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <AuthProvider>
          <Router>
            <Route exact path="/cliente/contato" element={<EmailContato message="Teste" />} />
            <Route exact path="/cliente/sub-digital/:proposal" element={<CPPContratoSubDigitalAprovar />} />
            <Route exact path="/cliente/sub-fisico/:proposal" element={<CPPContratoSubFisico />} />
            <Route exact path="/cliente/imo/sub-digital/:proposal" element={<ContratoClienteIMO />} />

            <Route exact path="/" element={<Home />} />
            <Route exact path="/granel" element={<Granel />} />
            <Route exact path="/granel/:simulation" element={<Granel />} />
            <Route exact path="/cpp" element={<CPP />} />
            <Route exact path="/cpp/:proposta" element={<CPP />} />
            <Route exact path="/cpp/:proposta/template" element={<CPP isTemplate />} />
            <Route exact path="/cpp/proposta-comercial/:proposa" element={<PropostaComercial />} />
            <Route exact path="/cpp/proposta-comercial-cockpit/:proposa" element={<PropostaComercialCockPit />} />
            <Route exact path="/cpp/sub-digital/:proposal" element={<CPPContratoSubDigital />} />
            <Route exact path="/cpp/sub-fisico/:proposal" element={<CPPContratoSubFisico />} />
            <Route exact path="/cpp/resumo-email" element={<ResumoEmailCPP />} />
            <Route exact path="/cpp/aprovacoes" element={<AprovacoesCPP />} />
            <Route exact path="/cpp/aprovadores/:proposta" element={<AprovadoresCPP />} />
            <Route exact path="/guia-vendedor" element={<GuiaVendedor />} />
            <Route exact path="/guia-vendedor/contato-usinas" element={<ContatoUsinas />} />
            <Route exact path="/guia-vendedor/vendedores" element={<VendedoresUsinas />} />
            <Route exact path="/guia-vendedor/cidades-atendidas" element={<CidadesAtendidas />} />
            <Route exact path="/guia-vendedor/guia-traco" element={<GuiaTraco />} />
            <Route exact path="/guia-vendedor/IMO" element={<GuiaDoVendedor />} />
            <Route exact path="/guia-contrato/IMO" element={<GuiContrato />} />
            <Route exact path="/imo" element={<IMO />} />
            <Route exact path="/imo/novas-propostas-abertas" element={<NovasPropostasAbertasIMO />} />
            <Route exact path="/imo/:proposta" element={<IMO />} />
            <Route exact path="/imo/proposta-comercial" element={<PropostaComercialImo />} />
            <Route exact path="/imo/aprovacoes" element={<AprovacoesIMO />} />
            <Route exact path="/imo/aprovacoes/:code" element={<AprovacoesIMO />} />
            <Route exact path="/imo/aprovadores/:proposta" element={<AprovadoresIMO />} />
            <Route exact path="/imo/spectra" element={<Spectra />} />
            <Route exact path="/imo/sub-digital" element={<IMOContratoSubDigital />} />
            <Route exact path="/imo/sub-fisico" element={<IMOContratoSubFisico />} />
            <Route exact path="/imo/resumo-email" element={<ResumoEmailViewIMO />} />
            <Route exact path="/imo/resumo-email-analise" element={<AprovacaoEmailViewIMO />} />
            <Route exact path="/imo/gerenciamento" element={<GerenciamentoClientesIMO />} />
            <Route exact path="/cpp/gerenciamento" element={<GerenciamentoClientesCPP />} />
            <Route exact path="/granel/gerenciamento" element={<GerenciamentoClientesGranel />} />
            <Route exact path="/granel/simulacoes" element={<GerenciamentoSimulacoesGranel />} />
            <Route exact path="/granel/simulacao/:simulacao" element={<VisualizarSimulacoesGranel />} />
          </Router>
        </AuthProvider>
      </AuthenticatedTemplate>

    </>
  );
}

export default Routes;
