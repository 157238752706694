import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './Components/App';
import Request from './Components/Request';

import { msalConfig } from './Config/authConfig';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <MsalProvider instance={msalInstance}>
      <Request>
        <HelmetProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </HelmetProvider>
      </Request>
    </MsalProvider>
  </QueryClientProvider>
  ,
);
