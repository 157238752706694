/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

import {
  postClient,
  postFabricaVC,
  postFabricaConcorrente,
  getClientByCod,
  getClients,
  getSegments,
  getReasons,
  getFabricasVC,
  getFabricaById,
  getProdutosFabrica,
  calculate,
  getCompaniasConcorrente,
  getFabricasConcorrente,
  getCiconsRange,
  getCiconsLatest,
  getCiconsAverage,
  getCiconsStandardDeviation,
  getCiconsPercentage,
  getSpecialDiscount,
  getValidateDates,
  getDeadlines,
  postProposta,
  postResultado,
  sendProposta,
  sendEmail,
  getSimulations,
  getSimulation,
  getVendedores,
  getVendedorByCode,
  getCoordenadorByCode,
  getDelegations,
  sendDelegation,
  sendDelegationMassive,
  deleteDelegation,
  deleteDelegationMassive,
  getVendedorEmail,
  getParameterByCod,
} from '../../Services/GranelServices';

import dataNosSeisUltimosMeses, { getValidCicons } from '../../Utils/calculateCiconMonths';

export const useParameters = (cod) => {
  const [parameter, setParameter] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParameter = async () => {
      try {
        const data = await getParameterByCod(cod);
        setParameter(data);
      } catch (err) {
        setError(err);
      }
    };

    fetchParameter();
  }, [cod]);
  return { parameter, error };
};

export const useGetClients = (
  enableFetch = false,
  sellerCode = null,
  withDelegation = true,
  globalAccess = false,
  search = '',
) => {
  const clientes = useQuery(['clientes', sellerCode, withDelegation, globalAccess], () => getClients(sellerCode, withDelegation, globalAccess, search), {
    enabled: enableFetch,
  });
  return clientes;
};

export const useGetClientByCod = (codCliente, enableFetch = false) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['cliente', codCliente], () => getClientByCod(codCliente), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useSaveClient = (clienteFields) => {
  const { mutate } = useMutation(postClient);
  const handleSave = () => {
    mutate(clienteFields, {
      onSuccess: (data) => {
        console.log('success useSaveClient | data: ', data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return handleSave;
};

export const useGetSegments = (enableFetch = true) => {
  const segments = useQuery(['segments'], () => getSegments(), {
    enabled: enableFetch,
  });
  return segments;
};

export const useGetReasons = (enableFetch = true) => {
  const reasons = useQuery(['reasons'], () => getReasons(), {
    enabled: enableFetch,
  });
  return reasons;
};

export const useGetFabricasVC = (enableFetch = true) => {
  const fabricasVC = useQuery(['fabricasVC'], () => getFabricasVC(), { enabled: enableFetch });
  return {
    data: fabricasVC.data?.data,
    isSuccess: fabricasVC.isSuccess,
    isLoading: fabricasVC.isLoading,
  };
};

export const useGetFabricaVCById = (fabricaId, enableFetch = false) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['fabrica', fabricaId], () => getFabricaById(fabricaId), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetProdutosFabrica = (factoryId, enableFetch = false) => {
  const produtosFabricaConcorrente = useQuery(['produtosFabricaConcorrente', factoryId], () => getProdutosFabrica(factoryId), {
    enabled: enableFetch,
  });

  const data = produtosFabricaConcorrente.data?.data?.map((produto) => ({
    ...produto,
    label: produto.material.description,
  }));

  return {
    data,
    isSuccess: produtosFabricaConcorrente.isSuccess,
    isLoading: produtosFabricaConcorrente.isLoading,
  };
};

export const useSaveFabricaVC = (fabricaVCFields) => {
  const { mutate } = useMutation(postFabricaVC);
  const handleSave = () => {
    mutate(fabricaVCFields, {
      onSuccess: (data) => {
        console.log('success useSaveFabricaVC | data: ', data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return handleSave;
};

export const useGetCompaniasConcorrente = (enableFetch = true) => {
  const companiasConcorrente = useQuery(['companiasConcorrente'], () => getCompaniasConcorrente(), { enabled: enableFetch });
  return {
    data: companiasConcorrente.data?.data,
    isSuccess: companiasConcorrente.isSuccess,
    isLoading: companiasConcorrente.isLoading,
  };
};

export const useGetFabricasConcorrente = (companyId, enableFetch = false) => {
  const fabricasConcorrente = useQuery(['fabricasConcorrente', companyId], () => getFabricasConcorrente(companyId), {
    enabled: enableFetch,
  });
  return {
    data: fabricasConcorrente.data?.data,
    isSuccess: fabricasConcorrente.isSuccess,
    isLoading: fabricasConcorrente.isLoading,
    refetch: fabricasConcorrente.refetch,
  };
};

export const useGetFabricaConcorrenteById = (fabricaId, enableFetch = false) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['fabrica', fabricaId], () => getFabricaById(fabricaId), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useSaveFabricaConcorrente = (fabricaConcorrenteFields) => {
  const { mutate } = useMutation(postFabricaConcorrente);
  const handleSave = () => {
    mutate(fabricaConcorrenteFields, {
      onSuccess: (data) => {
        console.log('success useSaveFabricaConcorrente | data: ', data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return handleSave;
};

export const useGetCiconsRange = (factoryMaterialId, type = 1, enableFetch = false) => {
  const d = new Date();
  d.setMonth(d.getMonth() - 2);
  const dates = dataNosSeisUltimosMeses(d);

  const dayFrom = dates[5].getDate().toString().padStart(2, '0');
  const monthFrom = (dates[5].getMonth() + 1).toString().padStart(2, '0');
  const yearFrom = dates[5].getFullYear();

  const dayTo = dates[0].getDate().toString().padStart(2, '0');
  const monthTo = (dates[0].getMonth() + 1).toString().padStart(2, '0');
  const yearTo = dates[0].getFullYear();

  const range = {
    from: `${yearFrom}-${monthFrom}-${dayFrom}`,
    to: `${yearTo}-${monthTo}-${dayTo}`,
  };

  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['cicons', factoryMaterialId, type, range], () => getCiconsRange(factoryMaterialId, type, range), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetCiconsLatest = (factoryMaterialId, type = 2, enableFetch = false) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['cicons', factoryMaterialId, type], () => getCiconsLatest(factoryMaterialId, type), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetCiconsAverage = (factoryMaterialId, cicons, type, enableFetch = false) => {
  const ciconsSearch = getValidCicons(cicons);

  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['average', factoryMaterialId, ciconsSearch, type], () => getCiconsAverage(factoryMaterialId, ciconsSearch, type), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetCiconsStandardDeviation = (
  factoryMaterialId,
  cicons,
  type,
  enableFetch = false,
) => {
  const ciconsSearch = getValidCicons(cicons);

  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['standardsDesviation', factoryMaterialId, ciconsSearch, type], () => getCiconsStandardDeviation(factoryMaterialId, ciconsSearch, type), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetCiconsPercentage = (fields, enableFetch = false) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['ciconsPercentage', fields], () => getCiconsPercentage(fields), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetSpecialDiscount = (
  factoryMaterialVcId,
  factoryMaterialCompetitorId,
  microregionId = null,
  enableFetch = false,
) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['specialDiscount', factoryMaterialVcId, factoryMaterialCompetitorId], () => getSpecialDiscount(factoryMaterialVcId, factoryMaterialCompetitorId, microregionId), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetValidateDates = (enableFetch = true) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['validateDates'], () => getValidateDates(), { enabled: enableFetch });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetDeadlines = (enableFetch = true) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['deadlines'], () => getDeadlines(), { enabled: enableFetch });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useCalculate = (fields, enableFetch) => {
  const calculateFields = JSON.parse(JSON.stringify(fields));
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['results', fields], () => calculate(calculateFields), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useSaveProposta = (granelFields) => {
  const { mutate } = useMutation(postProposta);
  const handleSave = (callback, errorCallback) => {
    mutate(granelFields, {
      onSuccess: (data) => {
        if (data.isSuccess) {
          callback(data?.data);
        } else {
          errorCallback(data);
        }
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleSave;
};

export const useSaveResultado = (granelResultadosFields) => {
  const { mutate } = useMutation(postResultado);
  const handleSave = (callback, errorCallback) => {
    mutate(granelResultadosFields, {
      onSuccess: (data) => {
        if (data.isSuccess) {
          callback(data?.data);
        } else {
          errorCallback(data);
        }
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleSave;
};

export const useSendProposta = (granelFields) => {
  const { mutate } = useMutation(sendProposta);
  const handleSave = (user, callback, errorCallback) => {
    mutate({ user, granelFields }, {
      onSuccess: (data) => {
        callback(data);
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleSave;
};

export const useSendEmail = (emailFields) => {
  const { mutate } = useMutation(sendEmail);
  const handleSave = (callback, errorCallback) => {
    mutate(emailFields, {
      onSuccess: (data) => {
        if (data.isSuccess) {
          callback(data?.data);
        } else {
          errorCallback(data);
        }
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleSave;
};

export const useSimulations = (enableFetch, accountId, sellerCode = null, s = null) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['simualtions', accountId], () => getSimulations(accountId, sellerCode, s), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useSimulation = (simulationId, enableFetch) => {
  const {
    isLoading, isSuccess, isRefetching, isError, data, error, refetch,
  } = useQuery(['simualtion', simulationId], () => getSimulation(simulationId), {
    enabled: enableFetch,
  });

  return {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
  };
};

export const useGetVendedores = (email) => useQuery(['vendedores'], () => getVendedores(email));

export const useGetVendedorEmail = (email) => {
  const res = useQuery(['vendedor_email', email], () => getVendedorEmail(email));
  return res;
};

export const useGetVendedorCode = (code) => {
  const res = useQuery(['vendedor_code', code], () => getVendedorByCode(code));
  return res;
};

export const useGetCoordenadorCode = (code, enableFetch = false) => {
  const res = useQuery(['coordenador_code', code], () => getCoordenadorByCode(code), {
    enabled: enableFetch,
  });
  return res;
};

export const useGetDelegations = (enableFetch, sellerOriginCode, sellerDelegationCode) => {
  const delegations = useQuery(['delegations', sellerOriginCode, sellerDelegationCode], () => getDelegations(sellerOriginCode, sellerDelegationCode), {
    enabled: enableFetch,
  });

  return delegations;
};

export const useSendDelegation = (delegationFields) => {
  const { mutate } = useMutation(sendDelegation);
  const handleSave = (callback, errorCallback) => {
    mutate(delegationFields, {
      onSuccess: (data) => {
        callback(data);
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleSave;
};

export const useSendDelegationMassive = (delegationFields) => {
  const { mutate } = useMutation(sendDelegationMassive);
  const handleSave = (callback, errorCallback) => {
    mutate(delegationFields, {
      onSuccess: (data) => {
        callback(data);
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleSave;
};

export const useDeleteDelegation = (id) => {
  const { mutate } = useMutation(deleteDelegation);
  const handleDelete = (callback, errorCallback) => {
    mutate(id, {
      onSuccess: (data) => {
        callback(data);
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleDelete;
};

export const useDeleteDelegationMassive = (id) => {
  const { mutate } = useMutation(deleteDelegationMassive);
  const handleDelete = (callback, errorCallback) => {
    mutate(id, {
      onSuccess: (data) => {
        callback(data);
      },
      onError: (error) => {
        errorCallback(error);
      },
    });
  };

  return handleDelete;
};

// export const useSendDelegation = async (delegationFields) => {
//   const delegation = await sendDelegation(delegationFields);

//   return delegation;
// };

// export const useDeleteDelegation = async (id) => {
//   const delegation = await deleteDelegation(id);

//   return delegation;
// };
