import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import logo from '../../../Assets/images/brand/logo-votorantim.png';
import { Container } from './styles';

function Login() {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  useEffect(() => {
    initializeSignIn();
  }, []);

  return (
    <Container>
      <div className="box">

        <img src={logo} alt="Votorantim" />
        <div>Faça login para acessar os recursos</div>
        {/* <button
          type="button"
          onClick={() => { initializeSignIn(); }}
        >
          Sign in
        </button> */}
      </div>
    </Container>
  );
}

export default Login;
