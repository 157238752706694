/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const ldsDalRing = keyframes`
   0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Box = styled.div`
  position: ${({ blocked }) => (blocked ? 'absolute' : 'fixed')};
  border-radius: ${({ blocked }) => (blocked ? '20px' : '0')};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(92, 92, 92, .5);
  backdrop-filter: blur(1px);
  z-index: 999;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.primary.main} transparent
      ${({ theme }) => theme.colors.primary.main} transparent;
    animation: ${ldsDalRing} 1.2s linear infinite;
  }
`;
