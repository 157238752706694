import axios from 'axios';

export const clientGranel = axios.create({
  baseURL:
    process.env.REACT_APP_API_GRANEL_URL || 'https://vc-granel-qa.azurewebsites.net/',
});

export const clientImo = axios.create({
  baseURL:
    process.env.REACT_APP_API_IMO_URL || 'https://vc-imo-qa.azurewebsites.net/',
});

export const clientImoWithoutAuth = axios.create({
  baseURL:
    process.env.REACT_APP_API_IMO_URL || 'https://vc-imo-qa.azurewebsites.net/',
});

export const clientCpp = axios.create({
  baseURL:
    process.env.REACT_APP_API_CPP_URL || 'https://vc-cpp-qa.azurewebsites.net/',
});

export const clientCppWithoutAuth = axios.create({
  baseURL:
    process.env.REACT_APP_API_CPP_URL || 'https://vc-cpp-qa.azurewebsites.net/',
});

export default axios.create();
